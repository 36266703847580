<template>
  <div class="page">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
    >
      <el-form-item
        label="所属区域"
        :rules="[
          { required: true, message: '请输入所属区域', trigger: 'blur' },
        ]"
        prop="belongStreet"
      >
        <v-input v-model="form.belongStreet" placeholder="请输入所属区域" />
      </el-form-item>

      <el-form-item
        label="所属楼宇"
        :rules="[
          { required: true, message: '请选择所属楼宇', trigger: 'blur' },
        ]"
        prop="belongBuilding"
      >
        <v-select
          clearable
          :options="buildingList"
          v-model="form.belongBuilding"
          placeholder="请选择所属楼宇"
        />
      </el-form-item>

      <el-form-item
        label="所属楼层"
        :rules="[
          { required: true, message: '请输入所属楼层', trigger: 'blur' },
        ]"
        prop="belongFloor"
      >
        <v-input v-model="form.belongFloor" placeholder="请输入所属楼层" />
      </el-form-item>
      <el-form-item
        label="企业名称"
        :rules="[
          { required: true, message: '请输入企业名称', trigger: 'blur' },
        ]"
        prop="companyName"
      >
        <v-input v-model="form.companyName" placeholder="请输入企业名称" />
      </el-form-item>
      <el-form-item
        label="纳税人代码"
        :rules="[
          { required: true, message: '请输入纳税人代码', trigger: 'blur' },
        ]"
        prop="taxpayerRegistrationNumber"
      >
        <v-input
          disabled
          v-model="form.taxpayerRegistrationNumber"
          placeholder="请输入纳税人代码"
        />
      </el-form-item>
      <el-form-item
        label="工商注册行业"
        :rules="[
          { required: true, message: '请输入工商注册行业', trigger: 'blur' },
        ]"
        prop="bureauRegisteredIndustryType"
      >
        <v-input
          v-model="form.bureauRegisteredIndustryType"
          placeholder="请输入工商注册行业"
        />
      </el-form-item>
      <el-form-item label="产业类型" prop="tradeType">
        <v-input v-model="form.tradeType" placeholder="请输入产业类型" />
      </el-form-item>
      <el-form-item label="注册/迁入时间" prop="registrationTime1">
        <v-date
          type="date"
          v-model="registrationTime1"
          placeholder="请选择注册/迁入时间"
          format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item label="经营内容" prop="businessScope">
        <v-input v-model="form.businessScope" placeholder="请输入经营内容" />
      </el-form-item>
      <el-form-item label="注册地址" prop="registrationAddress">
        <v-input
          v-model="form.registrationAddress"
          placeholder="请输入注册地址"
        />
      </el-form-item>
      <el-form-item label="经营地址" prop="businessAddress">
        <v-input v-model="form.businessAddress" placeholder="请输入经营地址" />
      </el-form-item>
      <el-form-item label="是否区内注册" prop="isAreaRegistration">
        <v-select
          clearable
          :options="yesOrNoList"
          v-model="form.isAreaRegistration"
          placeholder="请选择是否区内注册"
        />
      </el-form-item>
      <el-form-item label="场地产权" prop="placePropertyRight">
        <v-input
          v-model="form.placePropertyRight"
          placeholder="请输入场地产权"
        />
      </el-form-item>
      <el-form-item label="经营面积" prop="legalPerson">
        <v-input v-model="form.businessSize" placeholder="请输入经营面积" />
      </el-form-item>
      <el-form-item
        label="法人代表"
        prop="legalPerson"
        :rules="[
          { required: true, message: '请输入法人代表姓名', trigger: 'blur' },
        ]"
      >
        <v-input v-model="form.legalPerson" placeholder="请输入法人代表姓名" />
      </el-form-item>
      <el-form-item label="法人代表手机号码" prop="legalPersonMobile">
        <v-input
          v-model="form.legalPersonMobile"
          placeholder="请输入法人代表手机号码"
        />
      </el-form-item>
      <el-form-item label="财务人员" prop="financialStaff">
        <v-input
          v-model="form.financialStaff"
          placeholder="请输入财务人员姓名"
        />
      </el-form-item>
      <el-form-item label="财务人员手机号码" prop="financialStaffMobile">
        <v-input
          v-model="form.financialStaffMobile"
          placeholder="请输入财务人员手机号码"
        />
      </el-form-item>
      <el-form-item label="行政人员" prop="administrativeStaff">
        <v-input
          v-model="form.administrativeStaff"
          placeholder="请输入行政人员姓名"
        />
      </el-form-item>
      <el-form-item label="行政人员手机号码" prop="administrativeStaffMobile">
        <v-input
          v-model="form.administrativeStaffMobile"
          placeholder="请输入行政人员姓名手机号码"
        />
      </el-form-item>
      <el-form-item label="职工人数" prop="employeeNums">
        <v-input v-model="form.employeeNums" placeholder="请输入职工人数" />
      </el-form-item>
      <el-form-item label="党员人数" prop="partyMemberNums">
        <v-input v-model="form.partyMemberNums" placeholder="请输入党员人数" />
      </el-form-item>
      <el-form-item label="研发人数" prop="researchNums">
        <v-input v-model="form.researchNums" placeholder="请输入研发人数" />
      </el-form-item>
      <el-form-item label="大专以上人数" prop="juniorCollegeNums">
        <v-input
          v-model="form.juniorCollegeNums"
          placeholder="请输入大专以上人数"
        />
      </el-form-item>
      <el-form-item label="海外本科人数" prop="overseasUndergraduateNums">
        <v-input
          v-model="form.overseasUndergraduateNums"
          placeholder="请输入海外本科人数"
        />
      </el-form-item>
      <el-form-item label="国内博士人数" prop="inlandDoctorNums">
        <v-input
          v-model="form.inlandDoctorNums"
          placeholder="请输入国内博士人数"
        />
      </el-form-item>
      <el-form-item label="专利情况" prop="patentCondition">
        <v-input v-model="form.patentCondition" placeholder="请输入专利情况" />
      </el-form-item>
      <el-form-item label="企业归口" prop="leitungsorgan">
        <v-input v-model="form.leitungsorgan" placeholder="请输入企业归口" />
      </el-form-item>
      <el-form-item label="经济类型" prop="economicsType">
        <v-input v-model="form.economicsType" placeholder="请输入经济类型" />
      </el-form-item>
      <el-form-item label="企业状态" prop="businessStatus">
        <v-input v-model="form.businessStatus" placeholder="请输入企业状态" />
      </el-form-item>
      <el-form-item label="吊销日期" prop="revokeTime">
        <v-date
          type="date"
          v-model="form.revokeTime"
          placeholder="请选择吊销日期"
          format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item label="注册资本" prop="registeredCapital">
        <v-input
          v-model="form.registeredCapital"
          placeholder="请输入注册资本"
        />
      </el-form-item>
      <el-form-item label="分派状态" prop="dispatchStatus">
        <v-input v-model="form.dispatchStatus" placeholder="请输入分派状态" />
      </el-form-item>
      <el-form-item label="是否引导型企业" prop="isLeadType">
        <v-select
          clearable
          :options="yesOrNoList1"
          v-model="form.isLeadType"
          placeholder="请选择是否引导型企业"
        />
      </el-form-item>
      <el-form-item label="其他" prop="rests">
        <v-input v-model="form.rests" placeholder="请输入其他" />
      </el-form-item>
      <div class="footer-button">
        <v-button text="保存" type="success" @click="submitClick"></v-button>
      </div>
    </form-panel>
  </div>
</template>

<script>
import {
  updateBusinessInfoUrl,
  getBusinessInfosUrl,
  getBuildingInfoUrl,
} from "./api.js";
export default {
  name: "buildingManagementForm",
  data() {
    return {
      submitConfig: {
        queryMethodTe: true,
        queryMethod: "post",
        queryUrl: getBusinessInfosUrl,
        // submitUrl: updateBusinessInfoUrl,
      },
      communityList: [
        {
          value: "明珠社区",
          label: "明珠社区",
        },
      ],
      yesOrNoList: [
        {
          value: 0,
          label: "否",
        },
        {
          value: 1,
          label: "是",
        },
      ],
      yesOrNoList1: [
        {
          value: "0",
          label: "否",
        },
        {
          value: "1",
          label: "是",
        },
      ],
      buildingList: [],
      registrationTime1: null,
      form: {
        taxpayerRegistrationNumber: null,
        companyName: null,
        businessRegistrationNumber: null,
        tradeType: null,
        registrationTime: null,
        bureauRegisteredIndustryType: null,
        businessScope: null,
        businessAddress: null,
        registrationAddress: null,
        belongStreet: null,
        belongCommunity: "明珠社区",
        belongBuilding: null,
        belongFloor: null,
        belongGroup: null,
        leitungsorgan: null,
        economicsType: null,
        industryType: null,
        threeIndustry: null,
        businessStatus: null,
        isAreaRegistration: null,
        placePropertyRight: null,
        legalPerson: null,
        legalPersonMobile: null,
        financialStaff: null,
        financialStaffMobile: null,
        administrativeStaff: null,
        administrativeStaffMobile: null,
        employeeNums: null,
        partyMemberNums: null,
        researchNums: null,
        juniorCollegeNums: null,
        overseasUndergraduateNums: null,
        inlandDoctorNums: null,
        dispatchStatus: null,
        isLeadType: null,
        rests: null,
        revokeTime: null,
        logoutTime: null,
      },
    };
  },
  watch: {
    registrationTime1: {
      handler(nVal, oVal) {
        let year = nVal.getFullYear();
        let month =
          nVal.getMonth() + 1 < 10
            ? "0" + (nVal.getMonth() + 1)
            : nVal.getMonth() + 1;
        let day = nVal.getDate() < 10 ? "0" + nVal.getDate() : nVal.getDate();
        this.form.registrationTime = `${year}-${month}-${day}`;
      },
    },
  },
  created() {
    this.getBuildingList();
  },
  mounted() {
    this.getDetail();
    // const { id } = this.$route.query;
    // this.form.taxpayerRegistrationNumber = id;
    // this.$refs.formPanel.getData({ taxpayerRegistrationNumber: id });
    this.$setBreadList("编辑");
  },
  methods: {
    async getDetail() {
      let res = await this.$axios({
        url: `${getBusinessInfosUrl}`,
        method: "post",
        params: {},
        data: { taxpayerRegistrationNumber: this.$route.query.id },
      });
      console.log(res);
      this.form = res.data.records[0];
      this.registrationTime1 = res.data.records[0].registrationTime;
    },
    submitBefore() {
      let _this = this;
      this.form = [
        {
          // spaceId: _this.form.spaceId,
          // extra: {
          //   rent: _this.form.rent,
          //   renterName: _this.form.renterName,
          //   renterMobile: _this.form.renterMobile,
          //   size: _this.form.size,
          //   direction: _this.form.direction,
          //   monthPrice: _this.form.monthPrice,
          //   dayPrice: _this.form.dayPrice,
          //   hourPrice: _this.form.hourPrice,
          //   propertyPrice: _this.form.propertyPrice,
          //   publicPrice: _this.form.publicPrice,
          //   description: _this.form.description,
          //   picture: _this.form.picture,
          //   contactName: _this.form.contactName,
          //   contactMobile: _this.form.contactMobile,
          //   contactWechatQrcode: _this.form.contactWechatQrcode,
          // },
        },
      ];
      return true;
    },
    submitClick() {
      let params = this.form;
      this.$axios.post(`${updateBusinessInfoUrl}`, params).then((res) => {
        if (res.code == 200) {
          this.$router.go(-1);
        }
      });
    },
    submitSuccess(data) {
      return true;
    },
    // 获取楼宇列表
    getBuildingList() {
      this.$axios.get(`${getBuildingInfoUrl}`).then((res) => {
        if (res.code === 200) {
          res.data.forEach((item) => {
            item.value = item.buildingName;
            item.label = item.buildingName;
          });
          this.buildingList = res.data;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  box-sizing: border-box;
  height: 100%;
  .footer-button {
    padding-left: 130px;
    margin-bottom: 20px;
    > :nth-child(n) {
      min-width: 168px;
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
</style>
